<template>
  <validation-observer
    ref="form"
    class="page page__login login"
    tag="div"
  >
    <form class="login__form" @submit.prevent="auth">
      <esmp-loader v-if="isLoading" fix />

      <h1 class="login__title">
        Авторизация
      </h1>

      <div v-if="caption" class="login__caption">
        {{ caption }}
      </div>

      <validation-provider
        rules="required"
        v-slot="v"
        name="Логин"
        tag="div"
        class="login__input"
      >
        <esmp-input
          :class="{'esmp-input--is-not-empty' : autofilled.username}"
          ref="username"
          v-model="form.username"
          label="Введите логин"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>

      <validation-provider
        rules="required"
        v-slot="v"
        name="Пароль"
        tag="div"
        class="login__input"
      >
        <esmp-input
          :class="{'esmp-input--is-not-empty' : autofilled.password}"
          ref="password"
          :key="isPasswordShowed"
          label="Введите пароль"
          v-model="form.password"
          :error-message="v.errors.length ? v.errors[0] : ''"
          :icon="isPasswordShowed ? 'password-show' : 'password-hide'"
          :options="{ type: isPasswordShowed ? 'text' : 'password' }"
          @button-click="toggleVisibilityPassword"
        />
      </validation-provider>

      <div class="login__input">
        <esmp-button full-width type="submit">
          Войти
        </esmp-button>
      </div>

      <esmp-button
        v-if="isResetPasswordAvailable"
        view="function"
        @click="goToForgetPassword"
      >
        Забыли пароль?
      </esmp-button>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Login',

  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      isPasswordShowed: false,
      isLoading: false,
      autofilled: {
        username: false,
        password: false,
      },
    };
  },

  computed: {
    ...mapState('user', ['loggedInUser']),
    ...mapState('system', ['config']),
    ...mapGetters('system', ['firstOrderedSidebarLink']),

    caption() {
      return this.config.loginPageCaption;
    },

    isResetPasswordAvailable() {
      return this.config.isResetPasswordAvailable || false;
    },

    redirectUrl() {
      return this.$route.query.redirectFrom;
    },
  },

  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('user', ['setIsUserLoggedIn']),
    ...mapActions('system', ['getUserSettings']),

    goToForgetPassword() {
      this.$router.push({ name: 'ForgetPassword' });
    },

    toggleVisibilityPassword() {
      this.isPasswordShowed = !this.isPasswordShowed;
    },

    async auth() {
      const isFormValid = await this.$refs.form.validate();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        await this.login({
          username: this.form.username,
          password: this.form.password,
        });

        await this.getUserSettings();

        await this.setIsUserLoggedIn(true);

        const { url } = this.firstOrderedSidebarLink;

        if (this.loggedInUser?.name) {
          this.$EsmpNotify.$show(`Добро пожаловать, ${this.loggedInUser.name}!`, 'success');
        }

        await this.$router.push(this.redirectUrl || url || '/');
      } catch (e) {
        this.$EsmpNotify.$show('Неверный логин или пароль', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async checkAutoFill(fieldName) {
      if (this.$refs[fieldName]?.$el?.querySelector('input')) {
        this.autofilled[fieldName] = await this.detectAutofill(this.$refs[fieldName].$el.querySelector('input'));
      }
    },
    detectAutofill(element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button');
        }, 500);
      });
    },
  },

  created() {
    // fix for IE
    this.$EsmpLoader.hide();
  },
  mounted() {
    setTimeout(() => {
      this.checkAutoFill('username');
      this.checkAutoFill('password');
    }, 500);
  },
};
</script>
